<template>
    <v-card>
        <!-- FIAT & AMOUNT -->
        <v-card-text class="text-left">
            <div class="black--text font-weight-bold" style="font-size: 16px;">
                {{ $t('labels.payFrom') }}:
            </div>
            <v-row dense>
                <v-col cols="12" sm="9">
                    <custom-text-field
                        class="input--white full-width"
                        :value="form.paymentAmount"
                        :title="form.paymentAmount"
                        :loading="!ready.currency"
                        :disabled="!ready.fiatAmount"
                        outlined
                        type="currency"
                        @change="onInputAmount"
                        @input="calculateRate"
                    >
                        <template #prepend-inner>
                            <div class="ml-2"/>
                        </template>
                    </custom-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                    <custom-select
                        class="select--white full-width"
                        v-model="fiatObject"
                        :items="fiatObjectArray"
                        :disabled="!ready.fiatCurrency"
                        hide-details return-object
                        outlined
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        @input="() => {onChangeFiat(); calculateRate()}"
                    >
                        <template
                            #prepend-inner
                        >
                            <svg
                                v-if="!_.isEmpty(currencyObject)"
                                v-html="getIconHtml(fiatObject.value, true)"
                                class="cc-icon ml-2"
                                style="margin-top: 9px"
                            />
                        </template>

                        <template #item="scope">
                            <svg
                                v-html="getIconHtml(scope.item.value, true)"
                                class="cc-icon"
                            />
                            {{ scope && scope.item && scope.item.text ? scope.item.text : 0 }}
                        </template>
                    </custom-select>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- CRYPTO & NETWORK -->
        <v-card-text class="text-left">
            <div class="black--text font-weight-bold" style="font-size: 16px;">
                {{ $t('labels.payTo') }}:
            </div>
            <v-row dense>
                <v-col cols="12" sm="9">
                    <custom-text-field
                        class="input--white full-width"
                        :value="form.paymentAmountTo"
                        :title="form.paymentAmountTo"
                        :loading="!ready.currency"
                        disabled
                        outlined
                        type="currency"
                    >
                        <template #prepend-inner>
                            <div class="ml-2"/>
                        </template>
                    </custom-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                    <custom-select
                        class="select--white full-width"
                        v-model="currencyObject"
                        :items="currencyObjectArray"
                        :disabled="!ready.currency"
                        return-object
                        outlined
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        @input="() => {onChangeCurrency(); calculateRate();}"
                    >
                        <template
                            #prepend-inner
                        >
                            <svg
                                v-if="!_.isEmpty(currencyObject)"
                                v-html="getIconHtml(currencyObject.value)"
                                class="cc-icon ml-2"
                                style="margin-top: 9px"
                            />
                        </template>

                        <template #item="scope">
                            <svg
                                v-html="getIconHtml(scope.item.value)"
                                class="cc-icon"
                            />

                            {{ scope && scope.item && scope.item.text ? scope.item.text : 0 }}
                        </template>
                    </custom-select>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- PAYMENT METHODS -->
        <v-card-text class="text-left mb-3">
            <div class="black--text font-weight-bold" style="font-size: 16px;">
                {{ $t('labels.paymentDetails') }}:
            </div>

            <v-btn
                v-for="item in paymentMethodObjectArray"
                :key="`paymentMethod${item.id}`"
                class="px-6 mt-3 text-left"
                :color="item.payment_method !== form.apm ? 'gray-3' : 'primary'"
                :outlined="item.payment_method !== form.apm"
                block
                @click="onSelectPaymentMethod(item)"
            >
                <div class="full-width d-flex justify-space-between align-center" style="height: 30px;">
                    <div class="">
                        {{ item.name }}
                    </div>
                    <img
                        v-if="item.setting && item.setting.logo"
                        :src="item.setting.logo"
                        style="max-height: 100%"
                        :alt="`${item.name} logo`"
                    />
                </div>
            </v-btn>
        </v-card-text>

        <!-- BONUSES -->
        <v-card-text class="text-left pb-8">
            <div class="black--text font-weight-bold" style="font-size: 16px;">
                {{ $t('labels.select_bonus') }}:
            </div>
            <v-row dense>
                <v-col cols="12" sm="8">
                    <custom-autocomplete
                        class="autocomplete--white full-width"
                        v-model="autocompletePromocode"
                        :items="promocodes"
                        item-text="name"
                        item-value="promocode"
                        outlined hide-details
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        @update:search-input="handleAutocompleteInput"
                    >
                        <template #prepend-inner>
                            <div class="ml-2"></div>
                        </template>
                    </custom-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex align-end">
                    <v-btn
                        class="primary mb-2 full-width"
                        :disabled="isLoadingAttachBonus || !autocompletePromocode"
                        :loading="isLoadingAttachBonus"
                        light
                        @click="currentUserBonus"
                    >
                        {{ $t('buttons.get_bonus') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- ACTIONS -->
        <v-card-text class="pb-6">
            <v-btn
                :loading="isLoading || isInitOrderLoading"
                :disabled="isLoading || isInitOrderLoading || !ready.currency"
                block x-large light
                color="primary"
                @click="submit"
            >
                {{ $t('buttons.buyCrypto') }}
            </v-btn>
            <div v-if="paymentErr" class="red--text mt-4 text-subtitle-1" >
                {{ paymentErr }}
            </div>
            <div v-if="error" class="red--text mt-4 text-subtitle-1" >
                {{ error }}
            </div>
            <div v-if="Object.keys(errors).length" class="red--text mt-4 text-subtitle-1" >
                <div
                    v-for="error of Object.entries(errors)" :key="error[0]"
                >
                    {{ error[0] }} : {{ error[1]}}
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import TheWalletTabMixin from "@/components/modals/TheWallet/TheWalletTabMixin"
import axios from "axios"
import { mapState } from "vuex"
import CustomSelect from "@/components/ui/CustomSelect.vue";
import CustomTextField from "@/components/ui/CustomTextField.vue";
import CustomAutocomplete from "@/components/ui/CustomAutocomplete.vue";
import { confirmBonus, confirmBuyCrypto } from "@/helpers/confirm-alert";

export default {
    name: "TheWalletBuyCrypto",
    components: {CustomAutocomplete, CustomTextField, CustomSelect},

    mixins: [
        TheWalletTabMixin,
    ],
    props: {
        promocode: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            fiatObjectArray: [],
            currencyObjectArray: [],
            paymentMethodObjectArray: [],

            rates: {},

            promocodes: [],
            basePromocodes: [],
            currencyFilteredPromocodes: [],
            autocompletePromocode: null,
            isLoadingAttachBonus: false,

            fiatObject: {},
            currencyObject: {},
            networkObject: null,
            paymentMethodObject: null,

            currencyRelations: [ // brand to utPay currency relations
                { brandKey: 'BNB',  utPayKey: 'BNBBSC',     network: null },
                { brandKey: 'BTC',  utPayKey: 'BTC',        network: null },
                { brandKey: 'ETH',  utPayKey: 'ETH',        network: 'ETH' },
                { brandKey: 'LTC',  utPayKey: 'LTC',        network: null },
                { brandKey: 'USDC', utPayKey: 'USDCETH',    network: null },
                { brandKey: 'USDT', utPayKey: 'USDTE',      network: 'ERC20' },
                { brandKey: 'USDT', utPayKey: 'USDTT',      network: 'TRC20' },
                { brandKey: 'XLM',  utPayKey: 'XLM',        network: null },
                { brandKey: 'XRP',  utPayKey: 'XRP',        network: null },
            ],

            form: {
                currency            : "",   // BTC,
                address             : "",   // mmWjhvLvEzF8jpQC7jfygVH2zx7YkYyzqS,
                memo                : "",   // gu7t3d32lug
                paymentCurrency     : "",   // EUR,
                paymentAmount       : 0.00, // 100.0,
                paymentAmountTo     : 0.00, // 100.0,
                apm                 : "",   // WLT
                apmServiceId        : "",   // 12008
                countryOfResidence  : "",   // DE
            },

            isConfirmBuyCrypto: false,

            timeouts: {
                address : null,
                memo    : null,
            },

            ready: {
                currency        : false,
                network         : false,
                fiatCurrency    : false,
                fiatAmount      : false,
                paymentMethods  : false,
                address         : false,
            },

            isFormValid: true,
            isLoading: false,
            isInitOrderLoading: false,
            errors: {},
            error: '',
            paymentErr: null,
        }
    },
    computed: {
        ...mapState('app', {
            appCountry: 'country',
            appCurrency: 'currency',
        }),

        ...mapState('user', {
            userCryptoCurrent: state => state.crypto.current,
            userFiatCurrency : state => state.crypto.fiat_currency
        }),

        _() {
            return _;
        },

        networkObjectArray() {
            switch (this.currencyObject?.value) {
                case 'USDT'     : return [{text: 'ERC-20 token', value: 'ERC20'}, {text: 'TRC-20 token', value: 'TRC20'}]
                case 'ETH'      : return [{text: 'ETH - Ethereum (ERC20)', value: 'ETH'}]
                case 'BNB'      :
                case 'BTC'      :
                case 'LTC'      :
                case 'USDC'     :
                case 'XLM'      :
                case 'XRP'      :
                default         : return [];
            }
        },

        minFiatAmount() {
            return parseFloat(
                this.paymentMethodObject?.currencies[0]?.min ?? 0
            )
        },

        maxFiatAmount() {
            return parseFloat(
                this.paymentMethodObject?.currencies[0]?.max ?? 10000
            )
        },

        hasNetwork() {
            return !!(this.networkObjectArray?.length);
        },
    },

    created() {
        this.initForm()
    },
    mounted() {
        this.fetchBonusesPromocode()
    },
    watch: {
        userFiatCurrency() {
            this.initForm()
        },
        'form.paymentAmount': {
            handler(val) {
                this.calculateRate(val);
            }
        },
        currencyObject(val) {
            if (val) {
                this.filterPromocodesByCurrency(val.value)
            }
        },
        promocode: {
            handler(val) {
                if (val) {
                    this.handleAutocompleteInput(val)
                }
            },
            immediate: true,
        }
    },

    methods: {
        /**
         * Set ready state of form fields
         *
         * @param keys
         * @param value
         */
        setReady(keys, value = true) {
            keys.forEach(key => this.ready[key] = value)
        },

        async currentUserBonus() {
            if (this.$auth.user()) {
                const hasPendingBonus = this.$auth.user().pending_bonus || this.$auth.user().active_bonus
                const isCanAttachBonus = !hasPendingBonus || (hasPendingBonus && await confirmBonus())

                if (isCanAttachBonus) {
                    this.attachBonus();
                }
            }
        },

        handleAutocompleteInput(value) {
            if (!value) return false;

            this.autocompletePromocode = value;
            const isPromoNotFound = !this.promocodes.some((val) => {
                return val === value
            })

            if (value && isPromoNotFound) {
                this.promocodes = [value, ...this.currencyFilteredPromocodes]
            } else {
                if (this.currencyFilteredPromocodes.length) {
                    this.promocodes = [...this.currencyFilteredPromocodes]
                }
            }
        },
        async fetchBonusesPromocode() {
            try {
                const response = await axios.get('/api/get_bonus_promocodes');
                this.basePromocodes = [...this.basePromocodes, ...response.data];
                this.currencyFilteredPromocodes = [...this.currencyFilteredPromocodes, ...response.data];
                this.promocodes = [...this.promocodes, ...response.data];
            } catch (e) {
                console.error(e)
            }
        },

        filterPromocodesByCurrency(currency) {
            // this.autocompletePromocode = null
            //
            // this.promocodes = this.currencyFilteredPromocodes = this.basePromocodes.filter((promo) => {
            //     return Object.keys(promo.settings).includes(currency)
            // })
        },

        attachBonus(){
            if (!this.autocompletePromocode) {
                return
            }
            this.isLoadingAttachBonus = true;
            axios
                .post('/api/attach_deposit_bonus_from_promocode', { promocode: this.promocodes.find(p => p.name === this.autocompletePromocode)?.promocode ?? this.autocompletePromocode })
                .then(() => {
                    this.$store.dispatch('app/showSuccess', 'Promocode bonus successful');
                    this.autocompletePromocode = null
                })
                .catch(e => {
                    const errorMessage = e.response && e.response.data ? e.response.data.error || e.response.data.message : 'An unexpected error occurred';
                    this.$store.dispatch('app/showError', { message: 'Failed to attach bonus.', error: { message: errorMessage } });
                    console.log(e)
                })
                .finally(() => {
                    this.isLoadingAttachBonus = false;
                })
        },


        /**
         * Set initial form values
         */
        initForm() {
            Promise
                .all([
                    this.getUtPayCurrencyList(),
                    this.getPaymentMethods(),
                    this.getRates()
                ])
                .then(([currencyListResponse, paymentMethodsResponse, ratesResponse]) => {
                    this.currencyObjectArray = this.prepareCurrencyObjectArray(currencyListResponse)
                    this.fiatObjectArray = this.prepareFiatObjectArray(currencyListResponse)
                    this.paymentMethodObjectArray = this.buildPaymentMethodsObjectArray(paymentMethodsResponse)
                    this.rates = this.buildRateObject(ratesResponse)

                    this.onSelectPaymentMethod(this.paymentMethodObjectArray?.[0])
                })
                .then(() => {
                    const countryCode   = this.appCountry || "DE"
                    const currencyCode  = this.appCurrency?.label || "EUR"
                    const cryptoCode    = this.userCryptoCurrent?.name || "BTC"

                    this.form.countryOfResidence = countryCode

                    this.fiatObject = this.fiatObjectArray.find(i => i.value === currencyCode) ?? this.fiatObjectArray?.[0] ?? []
                    this.onChangeFiat()

                    this.currencyObject = this.currencyObjectArray.find(i => i.value === cryptoCode) ?? this.currencyObjectArray?.[0] ?? []
                    this.onChangeCurrency(this.currencyObject)

                    this.setReady(['currency', 'paymentMethods'])

                    this.calculateRate();
                })
                .catch(console.error)
        },

        /**
         * Handle change currency
         *
         * @param currency
         */
        onChangeCurrency(currency) {
            this.setReady(['network'], false)

            Promise
                .resolve(this.networkObjectArray ? this.networkObjectArray[0] : null)
                .then(network => {
                    this.networkObject = network
                    this.onChangeNetwork(network)
                    this.setReady(['network'])
                })
                .catch(console.error)
        },

        /**
         * Handle change network
         *
         * @param network
         */
        onChangeNetwork(network) {
            Promise
                .resolve(
                    this.currencyRelations.find(i => {
                        return i.brandKey === this.currencyObject?.value
                            && i.utPayKey === network?.value
                    })
                )
                .then(currencyRelation => {
                    this.form.currency = currencyRelation?.utPayKey || this.currencyObject?.value
                    // this.refreshDepositAddress(this.currencyObject?.value, network?.value)
                })
                .catch(console.error)

            // const currency = this.currencyRelations.find(i => {
            //     return i.brandKey === this.currencyObject?.value
            //         && i.utPayKey === network?.value
            // })
            // this.form.currency = currency?.utPayKey || ""
            // this.refreshDepositAddress(this.currencyObject?.value, network?.value)
        },

        /**
         * Handle change fiat
         */
        onChangeFiat() {
            this.form.paymentCurrency = this.fiatObject?.value
            this.form.paymentAmount = this.minFiatAmount.toFixed(2)
        },

        /**
         * Handle change amount
         *
         * @param e
         */
        onInputAmount(e) {
            e = e.replaceAll(',', '.')
            const amount = parseFloat(e.replace(/[^0-9.]/g, '') || 0)
            const min = this.minFiatAmount
            const max = this.maxFiatAmount

            this.form.paymentAmount = amount < min
                ? min.toFixed(2)
                : amount > max
                    ? max.toFixed(2)
                    : amount.toFixed(2)
        },

        calculateRate(val = undefined) {
            val = val !== undefined
                ? val.replace(',', '.')
                : undefined;
            const rateObject = this.rates?.[this.fiatObject?.value];
            const rate = rateObject?.[this.currencyObject?.value];
            const amount = val && !isNaN(parseFloat(val))
                ? parseFloat(val)
                : this.form.paymentAmount;
            if (rate && amount) {
                this.form.paymentAmountTo = (rate * amount).toFixed(8);
            }
        },

        /**
         * Refresh deposit credentials for selected crypto and network
         *
         * @param currency
         * @param network
         */
        refreshDepositAddress(currency, network) {
            this.setReady(['address', 'memo'], false)

            axios
                .get('/api/get_deposit_address', {
                    params: {
                        'currency'  : currency,
                        'network'   : network,
                    }
                })
                .then(res => {
                    // this.form.qrImage   = res.data?.qr;
                    this.form.address   = res.data?.address;
                    this.form.memo      = res.data?.memo;

                    this.filterPromocodesByCurrency(currency)
                    this.setReady(['address', 'memo'])
                })
                .catch(console.error)
        },

        /**
         * Handle select payment method
         *
         * @param method
         */
        onSelectPaymentMethod(method) {
            this.setReady(['fiatCurrency', 'fiatAmount'], false)

            this.paymentMethodObject = method
            this.form.apm = method.payment_method
            this.form.apmServiceId = method.service_id

            let fiatCurrencies = method?.setting?.is_fiat_filter
                ? method?.setting?.fiat_filter
                : null;
            fiatCurrencies ??= method.currencies;

            this.fiatObjectArray = fiatCurrencies.map(i => ({
                text    : i.currency ?? i,
                value   : i.currency ?? i,
            }))

            if(!fiatCurrencies.find(i => i.currency === this.fiatObject.value)) {
                this.fiatObject = this.fiatObjectArray[0]
            }

            this.onChangeFiat(this.fiatObject)
            this.setReady(['fiatCurrency', 'fiatAmount'])
        },

        /**
         * Prepare currency object array
         *
         * @param response
         * @return {{utPayKey: *, text: *, value: *, network: *}[]}
         */
        prepareCurrencyObjectArray(response) {
            const plainUtPay = this.currencyRelations.map(i => i.utPayKey) || []
            const filteredUtPay = response?.data?.data?.filter(i => plainUtPay.includes(i.currency)) || []

            let relationsFiltered = this.currencyRelations
                .filter(rel => {
                    return this.currenciesCrypto.find(i => i.name === rel.brandKey)
                        && filteredUtPay.find(i => rel.utPayKey === i.currency)
                })
                .map(rel => ({
                    text: rel.brandKey,
                    value: rel.brandKey,
                    utPayKey: rel.utPayKey,
                    network: rel.network
                }));

            relationsFiltered.unshift({
                text: 'NUMMUS',
                value: 'NUMMUS',
                utPayKey: 'NUMMUS',
                network: null,
            });

            return relationsFiltered;
        },

        /**
         * Prepare fiat object array
         *
         * @param response
         * @return {*}
         */
        prepareFiatObjectArray(response) {
            return response?.data?.data?.filter(i => 'FIAT' === i.type).map(i => ({
                text    : i.display,
                value   : i.currency,
            })) || []
        },

        /**
         * Prepare payment methods
         *
         * @param response
         * @return {*|*[]}
         */
        buildPaymentMethodsObjectArray(response) {
            return response?.data ?? [];

            // TODO: filter by user's country using payment method's tags
            // const payments = response?.data ?? []
            // const currentFiatCurrency = this.userFiatCurrency

            // return payments.filter(v => {
            //
            //     if (!v.setting.is_fiat_filter) {
            //         return true
            //     }
            //
            //     const fiatFilter = v.setting.fiat_filter ?? []
            //
            //     if (fiatFilter.length === 0) {
            //         return true
            //     }
            //
            //     return fiatFilter.includes(currentFiatCurrency)
            //
            // })

        },

        buildRateObject(response) {
            // TODO: filter by user's country using payment method's tags
            const rate = response?.data ?? {}

            return rate;
        },

        /**
         * Get currencies from UTPAY
         *
         * @return {Promise<AxiosResponse<any> | void>}
         */
        getUtPayCurrencyList() {
            const payload = {
                "sandbox": true, // Force sandbox mode
                "country": this.appCountry,
            }

            return axios
                .post('/api/user-dashboard/utpay/buy_crypto/get_currency_list', payload)
                .catch(console.error)
        },

        /**
         * Get brand payment methods
         *
         * @return {Promise<AxiosResponse<any> | void>}
         */
        getPaymentMethods() {
            const params = {
                filter: { type: 1 }
            }

            return axios
                .get('/api/user-dashboard/payment-methods', { params: params })
                .catch(console.error)
        },

        /**
         * Get exchange rates
         *
         * @return {Promise<AxiosResponse<any> | void>}
         */
        getRates() {
            const params = {};

            return axios
                .get('/api/get_all_rates', { params: params })
                .catch(console.error)
        },

        /**
         * Handle submit form
         */
        isIOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        },

        async submit() {
            if (!this.isConfirmBuyCrypto) {
                this.isConfirmBuyCrypto = await confirmBuyCrypto();

                if (!this.isConfirmBuyCrypto) {
                    return;
                }
            }

            this.errors = {};
            this.error = '';
            this.isInitOrderLoading = true;


            const payload = {
                "sandbox": true, // Force sandbox mode
                "userId": this.$auth.user()['api_user_id'],
                ...this.form
            }

            axios
                .post('/api/user-dashboard/utpay/buy_crypto/init_order', payload)
                .then(res => {
                    if(res?.data.hasOwnProperty("status") && res.data.status === 'FAIL') {
                        this.paymentErr = res.data.message
                    }

                    if (res?.data.hasOwnProperty("redirectUrl") && res.data.redirectUrl !== "") {
                        if (this.isIOS()) {
                            window.location.href = res.data.redirectUrl;
                        } else {
                            window.open(res.data.redirectUrl, "_blank");
                        }
                    }

                    res?.data?.data
                        ? window.open(res.data.data.redirectUrl ?? res.data.data.url, "_blank")
                        : this.processErrors(res.data.error.details)
                })
                .catch((e) => {
                    console.log('catch', e)
                    const errors = e?.response?.data?.errors ?? '';
                    if (typeof errors === 'object') {
                        this.errors = errors;
                    } else {
                        this.error = errors;
                    }
                })
                .finally(() => {
                    this.isInitOrderLoading = false;
                })
        },

        /**
         * Process UTPAY's response errors
         *
         * @param errorArray
         */
        processErrors(errorArray) { // TODO
            console.log('### PROCESS ERRORS:', errorArray)
        },
    },
}
</script>

<style lang="scss">
.cc-icon {
    width: 20px;
    height: 20px;
    margin: 0 6px 0 0;
}
.crypto_buy_crypto_form .v-input__append-outer {
    margin-top: 0 !important;
}

.pay-logo {
    > .v-btn__content {
        height: 100%;
    }
}
</style>
