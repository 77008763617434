<template>
    <v-card>
        <!-- CURRENCY CHIPS -->
        <v-card-text class="pb-0">
            <v-chip-group
                column
            >
                <v-chip
                    v-for="(item, index) in currenciesCrypto"
                    :key="index"
                    outlined
                    class="cursor-pointer py-1 px-2 align-center"
                    @click="onChangeCurrency(item.name)"
                >
                    <svg
                        v-html="getIconHtml(item.name)"
                        class="cc-icon mr-1"
                    />

                    <span >
                        {{ item.name }}
                    </span>
                </v-chip>
            </v-chip-group>
        </v-card-text>

        <!-- CURRENCY & NETWORK SELECTS -->
        <v-card-text>

            <v-row dense>
                <v-col
                    class="mb-4 text-left"
                    cols="12"
                    :sm="hasNetwork ? 6 : 12"
                >
                    <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                        {{ $t('labels.currency') }}:
                    </div>
                    <custom-select
                        class="select--white"
                        v-model="currentCryptoCurrency"
                        :items="currenciesCrypto.map(i => i.name)"
                        hide-details
                        outlined
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        @input="onChangeCurrency"
                    >
                        <template
                            v-if="$auth.user()['cryptoBalance']"
                            #prepend-inner
                        >
                            <svg
                                v-html="currentCryptoCurrencyIconHtml"
                                class="cc-icon"
                                style="margin-top: 9px"
                            />
                        </template>

                        <template #label>
                            {{ currentCryptoCurrency }}
                        </template>

                        <template #item="scope">
                            <svg
                                v-html="getIconHtml(scope.item)"
                                class="cc-icon"
                            />
                            {{ scope.item }}
                        </template>
                    </custom-select>
                </v-col>

                <v-col
                    v-if="hasNetwork"
                    cols="12"
                    sm="6"
                    class="text-left"
                >
                    <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                        {{ $t('labels.network') }}:
                    </div>
                    <custom-select
                        class="select--white"
                        :value="selectedCryptoNetwork"
                        :items="networksCrypto"
                        outlined return-object
                        :menu-props="{ dark: false, contentClass: 'menu--white' }"
                        @input="onChangeNetwork"
                    >
                        <template #label>
                            {{ selectedCryptoNetwork ? selectedCryptoNetwork.text : '' }}
                        </template>

                        <template #item="scope">
                            {{ scope.item.text }}
                        </template>
                    </custom-select>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- PROMO CODES -->
        <v-card-text class="text-left pt-0">
            <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                {{ $t('labels.select_bonus') }}:
            </div>
            <v-row dense>
                <v-col cols="12" sm="8" class="d-flex align-end">
                    <custom-autocomplete
                        class="autocomplete--white full-width"
                        v-model="autocompletePromocode"
                        :items="promocodes"
                        item-text="name"
                        item-value="promocode"
                        outlined
                        :menu-props="{ dark: false, contentClass: 'menu--white'}"
                        @update:search-input="handleAutocompleteInput"
                    />
                </v-col>
                <v-col cols="12" sm="4" class="d-flex align-end">
                    <v-btn
                        class="primary ml-2 mb-4 full-width"
                        :disabled="isLoadingAttachBonus || !autocompletePromocode"
                        :loading="isLoadingAttachBonus"
                        light
                        @click="currentUserBonus"
                    >
                        {{ $t('buttons.get_bonus') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- QR IMAGE & ADDRESS -->
        <v-card-text>
            <v-row dense>
                <v-col class="col-12 col-sm-4">
                    <div class="pa-3 white rounded">
                        <v-img
                            :src="deposit.qrImage"
                            aspect-ratio="1"
                            alt="QR Code"
                            max-width="333"
                            contain dark
                            class="rounded"
                        >
                            <template #placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="black lighten-5"
                                    />
                                </v-row>
                            </template>
                        </v-img>
                    </div>
                </v-col>

                <v-col class="col-12 col-sm-8 d-flex align-center">
                    <div class="grow text-left">
                        <template
                            v-for="item in [
                                {key: 'address' , ref: 'depositAddress' , label: $t('common.depositAddress'), class: 'mb-4'},
                                {key: 'memo'    , ref: 'depositMemo'    , label: $t('common.depositMemo')   , class: ''},
                            ]"
                        >
                            <div
                                v-if="deposit[item.key]"
                                :key="item.key"
                                :class="item.class"
                            >
                                <div class="black--text font-weight-bold text-left" style="font-size: 16px;">
                                    {{ item.label }}:
                                </div>
                                <custom-text-field
                                    class="input--white "
                                    v-model="deposit[item.key]"
                                    :title="deposit[item.key]"
                                    :ref="item.ref"
                                    outlined
                                    hide-details readonly
                                >
                                    <template #append>
                                        <v-btn
                                            dark
                                            class="primary mr-n4 mt-n2"
                                            :rounded="false"
                                            @click="toClipboard(deposit, item.key)"
                                        >
                                            {{ $t('common.copy') }}
                                        </v-btn>
                                    </template>
                                </custom-text-field>
                            </div>
                        </template>
                    </div>
                </v-col>
            </v-row>

            <div class="mt-4 text-left text-subtitle-2">
                <span class="primary--text font-weight-bold">
                    {{ $t('cryptoDescription.notice') }}
                </span>
                <span class="black--text">
                    {{ $tc('cryptoDescription.label', parseInt(confirmationCount), {currency: deposit.shortCurrency || deposit.currency, confirmation: confirmationCount}) }}
                </span>
                <br/>
                <span v-if="deposit.min" class="black--text">
                    {{ $t('cryptoDescription.minAmount', {currency: deposit.shortCurrency || deposit.currency, minAmount: deposit.min}) }}
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import TheWalletTabMixin from "@/components/modals/TheWallet/TheWalletTabMixin"
import CustomSelect from "@/components/ui/CustomSelect.vue";
import CustomAutocomplete from "@/components/ui/CustomAutocomplete.vue";
import CustomTextField from "@/components/ui/CustomTextField.vue";
import {confirmBonus} from "@/helpers/confirm-alert";

export default {
    name: "TheWalletDeposit",
    components: {CustomTextField, CustomAutocomplete, CustomSelect},
    mixins: [
        TheWalletTabMixin,
    ],
    props: {
        promocode: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            deposit: {
                address     : "",
                description : "Only send BTC to this address, 1 confirmation required.",
                qrImage     : "",
                memo        : "",
                currency    : "",
                network     : "",
                min         : null,
                max         : null,
                commission  : null,
            },
            timeouts: {
                address : null,
                memo    : null,
            },
            promocodes: [],
            basePromocodes: [],
            currencyFilteredPromocodes: [],
            autocompletePromocode: null,
            isLoadingAttachBonus: false,
            cryptoDeposit: null,
        }
    },
    watch: {
        promocode: {
            handler(val) {
                if (val) {
                    this.handleAutocompleteInput(val)
                }
            },
            immediate: true,
        }
    },
    created() {
        this.getCryptoDeposit();
    },
    mounted() {
        this.fetchBonusesPromocode()

        this.selectedCryptoNetwork = this.getCryptoCurrencyNetwork(this.defaultCryptoCurrency)?.[0]
        this.getDepositAddress(
            this.defaultCryptoCurrency,
            this.selectedCryptoNetwork
        )
    },
    methods: {
        getCryptoDeposit() {
            axios
                .get('/api/get_crypto_deposit')
                .then(res => {
                    this.cryptoDeposit = res.data.data
                    this.getDepositData()
                })
        },
        getDepositData() {
            let currency = this.cryptoDeposit?.currencies?.find(v => v.currency === (this.deposit.shortCurrency || this.deposit.currency))
            this.deposit.min = currency?.min || 0
            this.deposit.commission = currency?.commission || 0
        },
        async currentUserBonus() {
          if (this.$auth.user()) {
              const hasPendingBonus = this.$auth.user().pending_bonus || this.$auth.user().active_bonus
              const isCanAttachBonus = !hasPendingBonus || (hasPendingBonus && await confirmBonus())

              if (isCanAttachBonus) {
                this.attachBonus();
              }
          }
        },
        handleAutocompleteInput(value) {
            if (!value) return false;
            this.autocompletePromocode = value;
            const isPromoNotFound = !this.promocodes.some((val) => {
                return val === value
            })

            if (value && isPromoNotFound) {
                this.promocodes = [value, ...this.currencyFilteredPromocodes]
            } else {
                if (this.currencyFilteredPromocodes.length) {
                    this.promocodes = [...this.currencyFilteredPromocodes]
                }
            }
        },
        async fetchBonusesPromocode() {
            try {
                const response = await axios.get('/api/get_bonus_promocodes');
                this.basePromocodes = [...this.basePromocodes, ...response.data];
                this.currencyFilteredPromocodes = [...this.currencyFilteredPromocodes, ...response.data];
                this.promocodes = [...this.promocodes, ...response.data];
            } catch (e) {
                console.error(e)
            }
        },
        attachBonus(){
            if (!this.autocompletePromocode) {
               return
            }
            this.isLoadingAttachBonus = true;
            axios
                .post('/api/attach_deposit_bonus_from_promocode', { promocode: this.promocodes.find(p => p.name === this.autocompletePromocode)?.promocode ?? this.autocompletePromocode })
                .then(() => {
                    this.$store.dispatch('app/showSuccess', 'Promocode bonus successful');
                    this.autocompletePromocode = null
                })
                .catch(e => {
                    const errorMessage = e.response && e.response.data ? e.response.data.error || e.response.data.message : 'An unexpected error occurred';
                    this.$store.dispatch('app/showError', { message: 'Failed to attach bonus.', error: { message: errorMessage } });
                    console.log(e)
                })
                .finally(() => {
                    this.isLoadingAttachBonus = false;
                })
        },

        /**
         * Change currency handler
         * @param currency
         */
        onChangeCurrency(currency) {
            this.selectedCryptoCurrency = currency
            this.filterPromocodesByCurrency(currency)

            Promise
                .resolve(this.networksCrypto ? this.networksCrypto[0] : null)
                .then(network => {
                    this.selectedCryptoNetwork = network
                    this.getDepositAddressFromCurrent()
                    this.getDepositData()
                })
                .catch(console.error)
        },

        filterPromocodesByCurrency(currency) {
            // this.autocompletePromocode = null

            // this.promocodes = this.currencyFilteredPromocodes = this.basePromocodes.filter((promo) => {
            //     return Object.keys(promo.settings).includes(currency)
            // })
        },

        /**
         * Change network handler
         * @param network
         */
        onChangeNetwork(network) {
            Promise
                .resolve(this.selectedCryptoNetwork = network)
                .then(() => this.getDepositAddressFromCurrent())
                .catch(console.error)
        },

        getDepositAddressFromCurrent() {
            Promise
                .resolve(Object.keys(this.deposit).map(key => this.deposit[key] = ""))
                .then(() => {
                    this.getDepositAddress(this.currentCryptoCurrency, this.selectedCryptoNetwork)
                })
                .catch(console.error)
        },

        getDepositAddress(currency, network) {
            axios
                .get('/api/get_deposit_address', {
                    params: {
                        'currency'            : currency,
                        'network'             : network?.value || null,
                        // 'payment_method_id'   : this.$auth.user()?.crypto_payment?.id
                    }
                })
                .then(res => {
                    this.deposit.qrImage    = res.data?.qr;
                    this.deposit.address    = res.data?.address;
                    this.deposit.memo       = res.data?.memo;
                    this.deposit.currency   = res.data?.currency;
                    this.deposit.shortCurrency   = res.data?.short_currency;
                    this.deposit.network    = res.data?.network;
                })
                .catch(e => {
                })
                .finally(() => {
                    this.filterPromocodesByCurrency(currency)
                    this.getDepositData();
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.cc-icon {
    width: 20px;
    height: 20px;
    margin: 0 6px 0 0;
}

.v-chip {
    border-color: var(--v-primary-base) !important;
    color: var(--v-background-base);
}
</style>
