import Vuetify from '../../../plugins/vuetify'
import {MODALS} from '@/configs/modals';

export default {

    tagEventInc (state) {
        state.tagEvent = state.tagEvent + 1
    },
    /**
     * Main Toast
     */
    showToast: (state, toast) => {
        const { color, timeout, message } = toast

        state.toast = {
            message,
            color,
            timeout,
            show: true
        }
    },
    hideToast: (state) => {
        state.toast.show = false
    },
    resetToast: (state) => {
        state.toast = {
            show: false,
            color: 'black',
            message: '',
            timeout: 3000
        }
    },

    setPlatform: (state, platform) => {
        state.platform = platform;
    },

    /**
     * Theme and Layout
     */
    setGlobalTheme: (state, theme) => {
        Vuetify.framework.theme.dark = theme === 'dark'
        state.globalTheme = theme
    },
    setRTL: (state, isRTL) => {
        Vuetify.framework.rtl = isRTL
        state.isRTL = isRTL
    },
    setContentBoxed: (state, isBoxed) => {
        state.isContentBoxed = isBoxed
    },
    setMenuTheme: (state, theme) => {
        state.menuTheme = theme
    },
    setToolbarTheme: (state, theme) => {
        state.toolbarTheme = theme
    },
    setTimeZone: (state, zone) => {
        state.time.zone = zone
    },
    setTimeFormat: (state, format) => {
        state.time.format = format
    },
    setCurrency: (state, currency) => {
        state.currency = currency
    },
    setToolbarDetached: (state, isDetached) => {
        state.isToolbarDetached = isDetached
    },
    setLayout: (state, payload) => {
        state.layout = { ...state.layout, ...payload };
    },
    setBanners: (state, banners) => {
        state.banners = banners
    },
    setBrandSettings: (state, settings) => {
        state.brandSettings = settings
    },
    setTags: (state, settings) => {
        state.tags = settings
    },
    setScripts: (state, settings) => {
        state.scripts = settings
    },
    setTopWinnersList: (state, array) => {
        state.top_winners_list = array
    },
    setBrandSkin: (state, skin) => {
        state.brandSkin = skin
    },
    setControlContents: (state, payload) => {
        state.controlContents = payload;
    },
    setUserBonuses: (state, payload) => {
        state.userBonuses = payload;
    },
    setTranslationSettings: (state, payload) => {
        state.translationSettings = payload;
    },
    setCountry: (state, payload) => {
        state.country = payload;
    },
    isLoading: (state, payload) => {
        state.isLoading = !!payload;
    },
    updateActiveWindow: (state, payload) => {
        switch (payload.type) {
            case MODALS.REGISTER:
                state.windows.login = false;
                state.windows.forgot = false;
                state.windows.register = payload.active;
                break;
            case MODALS.LOGIN:
                state.windows.register = false;
                state.windows.forgot = false;
                state.windows.login = payload.active;
                break;
            case MODALS.WALLET:
                state.windows.login = false;
                state.windows.forgot = false;
                state.windows.register = false;
                state.windows.wallet = payload.active;
                break;
            case MODALS.FORGOT:
                state.windows.register = false;
                state.windows.login = false;
                state.windows.forgot = payload.active;
                break;
            case MODALS.VERIFY_EMAIL:
                state.windows.register = false;
                state.windows.login = false;
                state.windows.verifyEmail = payload.active;
                break;
            case MODALS.BET_TIMEOUT:
                state.windows.betTimeout = payload.active;
                break;
            case MODALS.USER_DASHBOARD_MOBILE:
                state.windows.userDashboardMobile = payload.active;
                break;
            case MODALS.ERROR:
                state.windows.error = payload.active;
                state.errorMessage = payload.message;
                break;
        }
    },
    setProfileDrawerActive: (state, payload) => {
        state.profileDrawer = !!payload;
    },
    setRedirectOnAuth: (state, payload) => {
        state.redirectOnAuth = payload;
    },
    updateUserDashboardMobileDialog: (state, payload) => {
      state.userDashboardMobileDialog = payload
    },
    setPreloader: (state, payload) => {
      state.preloader = payload
    },
    setGameLinkBeforeLogin: async (state, payload) => {
        return new Promise(() => {
            state.gameLinkBeforeLogin = payload
        })
    },
    setBonusUpdateKey: (state) => {
        state.bonusUpdateKey = Math.random().toString(16).substr(2, 10)
    },
    setProviderSort: (state, payload) => {
      state.providersSort = payload
    }
}
